<template>
  <b-card no-body class="p-2">
    <h3 class="mb-2">
      {{
        jobSchedule.puestoHorarioId == 0
          ? $t("Routes.JobScheduleAdd")
          : $t("Routes.JobScheduleEdit")
      }}
    </h3>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.jobSchedule.columns.job')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.jobSchedule.columns.job')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="Puesto"
                  v-model="jobSchedule.puestoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :options="jobs"
                  :reduce="(option) => option.puestoId"
                  @search="searchJobs"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.jobSchedule.columns.schedule')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.jobSchedule.columns.schedule')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="Puesto"
                  v-model="jobSchedule.horarioId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="texto"
                  :options="schedules"
                  :reduce="(option) => option.id"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingJobSchedule" small class="mr-1" />
            {{ jobSchedule.puestoHorarioId == 0 ? $t("Lists.Add") : $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import optionService from '@/services/option.service'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import router from '@/router'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormGroup,
    BButton,

    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  setup() {
    const jobs = ref([])
    const schedules = ref([])
    const addingJobSchedule = ref(false)
    const jobSchedule = ref({
      puestoHorarioId: 0,
      puestoId: 0,
      horarioId: 0,
    })

    const blankJobSchedule = {
      puestoHorarioId: 0,
      puestoId: 0,
      horarioId: 0,
    }

    const {
      fetchJobSchedule,
      createJobSchedule,
      updateJobSchedule,
      fetchJobs,
    } = humanResources()

    const {
      fetchOptions,
    } = optionService()

    const resetJobSchedule = () => {
      jobSchedule.value = JSON.parse(JSON.stringify(blankJobSchedule))
    }

    if (router.currentRoute.params.puestoHorarioId) {
      fetchJobSchedule(router.currentRoute.params.puestoHorarioId, data => {
        jobSchedule.value = data
      })
    } else {
      jobSchedule.value = JSON.parse(JSON.stringify(blankJobSchedule))
    }
    fetchOptions({ nombreDominio: 'RecursosHumanosHorario' }, data => {
      schedules.value = data
    })
    const searchJobs = text => {
      fetchJobs({ searchQuery: text, limit: 50 }, data => {
        jobs.value = data.puestos
      })
    }
    fetchJobs({ limit: 50 }, data => {
      jobs.value = data.puestos
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetJobSchedule)

    const onSubmit = () => {
      addingJobSchedule.value = true
      if (jobSchedule.value.puestoHorarioId === 0) {
        createJobSchedule(jobSchedule.value, () => {
          addingJobSchedule.value = false
          router.push({ name: 'apps-human-resources-job-schedule-list' })
        })
      } else {
        updateJobSchedule(jobSchedule.value.puestoHorarioId, jobSchedule.value, () => {
          addingJobSchedule.value = false
          router.push({ name: 'apps-human-resources-job-schedule-list' })
        })
      }
    }

    return {
      jobs,
      schedules,
      jobSchedule,
      addingJobSchedule,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,
      searchJobs,

    }
  },
}
</script>
